import React from 'react';
import { Link } from 'react-router-dom';
import { RiAccountCircleFill } from 'react-icons/ri';
import { LiaFileSolid } from 'react-icons/lia';
import { MdOutlineStars } from 'react-icons/md';

const Home = () => {
  return (
    <div className='w-full h-[calc(100vh-var(--custom-header-height))]'>
      <div className='w-full h-full flex items-center justify-center'>
        <div className=''>
          <h1>Welcome to the DARE Platform. Please select a tool.</h1>
          <div className='flex flex-col gap-3 md:gap-0 md:flex-row items-center justify-between my-16'>
            {/* account */}
            <Link
              to='/account' // Specify the path you want to navigate to
              className='flex flex-col items-center justify-center text-black/80 hover:text-[var(--custom-bg-color)]'
            >
              <RiAccountCircleFill size={64} />
              <p className='my-2'>Account</p>
            </Link>

            <Link
              to='/upload' // Specify the path for the file link
              className='flex flex-col items-center justify-center text-black/80 hover:text-[var(--custom-bg-color)]'
            >
              <LiaFileSolid size={64} />
              <p className='my-2'>Files</p>
            </Link>

            {/* Model Chat */}
            <Link
              to='/chat' // Specify the path for the model chat link
              className='flex flex-col items-center justify-center text-black/80 hover:text-[var(--custom-bg-color)] '
            >
              <MdOutlineStars size={64} />
              <p className='my-2'>Model Chat</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
