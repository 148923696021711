export const getFormattedDate = (givenDate) => {
  let date = new Date(givenDate);
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  let day = date.getDate().toString().padStart(2, '0');
  let year = date.getFullYear();

  let formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

export const getFormattedFilename = (value) => {
  let parts = value.split('/');
  return parts[parts.length - 1];
};

export const getFormatFileSize = (sizeInKB) => {
  const units = ['KB', 'MB', 'GB', 'TB'];
  let size = sizeInKB;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  size = size.toFixed(1);

  return `${size}${units[unitIndex]}`;
};

// function to get cookies from browser if they exist
export const getCookie = (name) => {
  const cookieArr = document.cookie.split(';');
  for (let cookie of cookieArr) {
    const [key, value] = cookie.trim().split('=');
    if (key === name) {
      return decodeURIComponent(value);
    }
  }
  return null;
};
