import React, { useState, useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect'; // Importing Typewriter effect
import { textualData } from '../data';
import { useUser } from '../context/UserContext';
import classNames from 'classnames';
import Select from 'react-select';

const options = [{ value: 'claude-v1', label: 'claude-v1' }];

const Chat = () => {
  const { loggedInUser } = useUser();
  const [model, setModel] = useState('');
  const [apiKey, setAPIKey] = useState('');
  const [message, setMessage] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const socketRef = useRef(null);
  const chatContainerRef = useRef(null); // Reference to the chat container

  // Use effect to establish a WebSocket connection when the API key is provided
  useEffect(() => {
    if (apiKey) {
      const socketUrl = `wss://daretools.com/ws/claude/?api_key=${encodeURIComponent(
        apiKey
      )}`;
      socketRef.current = new WebSocket(socketUrl);

      socketRef.current.onopen = () => {
        setChatLog((prevLog) => [
          ...prevLog,
          {
            sender: 'System',
            text: 'Connected to Claude AI.',
            key: `system-${Date.now()}`,
          },
        ]);
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.response) {
          setChatLog((prevLog) => [
            ...prevLog,
            {
              sender: 'Claude',
              text: data.response,
              key: `claude-${Date.now()}`,
            },
          ]);
        } else if (data.error) {
          setChatLog((prevLog) => [
            ...prevLog,
            { sender: 'Error', text: data.error, key: `error-${Date.now()}` },
          ]);
        }
        setIsTyping(false);
      };

      socketRef.current.onerror = (error) => {
        setChatLog((prevLog) => [
          ...prevLog,
          {
            sender: 'System',
            text: 'WebSocket connection error.',
            key: `system-${Date.now()}`,
          },
        ]);
      };

      socketRef.current.onclose = () => {
        setChatLog((prevLog) => [
          ...prevLog,
          {
            sender: 'System',
            text: 'WebSocket connection closed.',
            key: `system-${Date.now()}`,
          },
        ]);
      };

      // Cleanup on component unmount or when the API key changes
      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, [apiKey]);

  // Scroll to bottom whenever chatLog changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatLog]);

  const handleSendMessage = () => {
    if (
      message.trim() !== '' &&
      socketRef.current &&
      socketRef.current.readyState === WebSocket.OPEN
    ) {
      setChatLog((prevLog) => [
        ...prevLog,
        { sender: 'You', text: message, key: `user-${Date.now()}` },
      ]);

      socketRef.current.send(
        JSON.stringify({
          prompt: `\n\nHuman: ${message}\n\nAssistant:`,
          max_tokens_to_sample: 1000,
          stop_sequences: ['\n\nHuman:'],
          model: model || 'claude-v1',
        })
      );

      setMessage('');
      setIsTyping(true);
    } else {
      console.error('WebSocket is not open. Unable to send message.'); // Debugging: Log if WebSocket is not open
    }
  };

  const isDisabled = () => {
    return !(apiKey && message);
  };

  return (
    <div className='w-full h-[calc(100vh-var(--custom-header-height))]'>
      <div className='mx-16 border border-[#c9b5ac] bg-[#ece6e4] px-2 py-1 '>
        {/* topbox */}
        <div className='inline-block text-black mx-4 my-2 bg-[#D9D9D9] px-4 py-3 border border-black'>
          <p className='font-normal text-[12px]'>
            {loggedInUser?.username} ::: Hello, can you please give me some good
            advice for a chat interface? We are looking for something basic that
            can chat with different models using an API key.
          </p>
        </div>
        {/* instructions section */}
        <div className='px-4 py-2'>
          <p className='text-sm text-normal'>
            Here's some concise advice for creating a basic chat interface that
            can interact with different language models via API.
          </p>
          <div>
            <ol className='list-decimal list-inside my-4'>
              {textualData.map((item) => (
                <li
                  key={item.id}
                  className='mb-4'
                >
                  <span className='text-sm font-normal'>{item.title}</span>
                  <ul className='list-disc list-outside ml-6'>
                    {item.points.map((point, index) => (
                      <li
                        key={index}
                        className='text-sm font-normal'
                      >
                        {point}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
          </div>
          <p className='text-sm text-normal mb-1'>
            Would you like me to elaborate on any specific aspect of this
            advice?
          </p>
        </div>
      </div>
      {/* Chat log */}
      <div
        className='h-[20vh] overflow-y-scroll border border-[#c9b5ac] px-8 mx-16 my-2'
        ref={chatContainerRef}
      >
        {chatLog.map((entry) => (
          <div
            key={entry.key}
            className={classNames(
              'my-2 p-2 rounded-lg max-w-[100%]', // Adding padding and rounded corners for both "You" and "Claude"
              {
                'bg-gray-300 text-left': entry.sender === 'You',
                'text-right': entry.sender === 'Claude',
              }
            )}
          >
            <strong>{entry.sender}:</strong>{' '}
            {entry.sender === 'Claude' ? (
              <Typewriter
                options={{
                  delay: 50,
                  cursor: '', // Remove the blinking cursor after typing
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(entry.text)
                    .callFunction(() => {
                      typewriter.stop(); // Prevents erasing by stopping after typing
                    })
                    .start();
                }}
              />
            ) : (
              entry.text
            )}
          </div>
        ))}
        {isTyping && (
          <div className='text-right'>
            <strong>Claude:</strong> <span className='animate-pulse'>...</span>
          </div>
        )}
      </div>
      <div className='flex flex-col items-center justify-between mx-16 pb-16'>
        <textarea
          placeholder='No thank you -- can you suggest a color scheme?'
          className='w-full h-16 px-4 py-2 text-sm rounded-lg my-2 focus:outline-none'
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        <div className='w-full flex items-center justify-between'>
          <div className='w-2/3'>
            <div className='flex items-center justify-start gap-4 my-2'>
              <label
                htmlFor='API Key'
                className='font-normal w-16'
              >
                API Key
              </label>
              <input
                value={apiKey}
                type='password'
                onChange={(e) => setAPIKey(e.target.value)}
                placeholder='api-key-here'
                className='focus:outline-none bg-white border border-[#D9D9D9] p-2 rounded-lg flex-1'
              />
            </div>
            <div className='flex items-center justify-start gap-1 md:gap-4'>
              <label
                htmlFor='Model'
                className='font-normal w-16'
              >
                Model
              </label>
              <Select
                name='model'
                id='model'
                options={options}
                defaultValue={options[0]} // Set the default selected option
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                    margin: 0,
                    marginLeft: '5px',
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: '1px solid #D9D9D9',
                    borderRadius: '8px',
                    backgroundColor: '#f7f7f7',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: '#D9D9D9',
                    },
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '4px',
                  }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    padding: '0',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                    cursor: 'pointer',
                    color: '#863e39',
                    '&:hover': {
                      color: '#863e39',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 2,
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    cursor: 'pointer',
                    backgroundColor: state.isSelected
                      ? '#f1c4c2' // Background color for the selected option
                      : state.isFocused
                      ? '#f1c4c2' // Background color on hover
                      : '#fff', // Default background color
                    color: '#000', // Text color
                    '&:hover': {
                      backgroundColor: '#f1c4c2',
                    },
                  }),
                }}
              />
            </div>
          </div>
          <button
            className={`w-max md:w-36 font-semibold bg-[var(--custom-bg-color)] text-white px-2 md:px-3 py-2 rounded md:rounded-3xl ${
              isDisabled() ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'
            }`}
            type='button'
            onClick={handleSendMessage}
            disabled={isDisabled()}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
