import React from 'react';
import Header from './components/header/Header'; // Adjust the import path as necessary
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      <Header />
      <Outlet /> {/* This will render the matched route */}
    </div>
  );
};

export default Layout;
