import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
  getFormattedDate,
  getFormattedFilename,
  getFormatFileSize,
  getCookie,
} from '../utility';

export const fetchUserFiles = async () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const csrfToken = getCookie('csrftoken');
  const response = await fetch(`${baseUrl}/api/files`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Error fetching files');
  }

  return response.json();
};

const uploadFile = async (file) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${baseUrl}/api/upload/`, {
    method: 'POST',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
    body: formData,
    credentials: 'include',
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Error uploading file');
  }

  return response.json();
};

// const getCsrfToken = async () => {
//   const baseUrl = process.env.REACT_APP_API_BASE_URL;
//   try {
//     await fetch(`${baseUrl}/api/auth/get-csrf-token/`, {
//       method: 'GET',
//       credentials: 'include',
//     });
//   } catch (error) {
//     console.error('Error fetching CSRF token:', error);
//   }
// };

const Upload = () => {
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  const {
    data: userFiles = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['userFiles'],
    queryFn: fetchUserFiles,
  });

  const mutation = useMutation({
    mutationFn: uploadFile,
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries(['userFiles']); // Invalidate the user files query to refresh the data
      setFile(null);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.type !== 'application/pdf') {
        setError('Please upload a PDF file.');
        setFile(null);
        return;
      }

      if (selectedFile.size > 1024 * 1024) {
        setError('File size must be less than 1MB.');
        setFile(null);
        return;
      }

      setError('');
      setFile(selectedFile);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (file) {
      mutation.mutate(file);
    } else {
      alert('Please select a valid file to upload.');
    }
  };

  if (isLoading) return <p>Loading files...</p>;
  if (isError) return <p>Error fetching files.</p>;

  return (
    <div className='w-full h-[calc(100vh-var(--custom-header-height))] px-16 py-8'>
      <div className='flex flex-col items-center justify-center w-full h-full'>
        <div className='flex flex-col items-start justify-center w-full md:w-[600px]'>
          <p className='text-md mb-2 w-full text-left text-lg'>
            Please select a file to upload to your account.
          </p>
          <div className='flex items-center justify-start w-full md:w-96 gap-8'>
            <span className='w-96 bg-white px-4 py-3 rounded-lg h-12 border border-[#D9D9D9]'>
              {file ? file.name : 'no file selected!'}
            </span>
            <input
              type='file'
              id='file-upload'
              className='hidden'
              onChange={handleFileChange}
            />
            <label
              htmlFor='file-upload'
              className='my-5 h-10 bg-[var(--custom-bg-color)] text-white px-4 py-2 rounded-3xl text-sm font-semibold cursor-pointer'
            >
              Browse
            </label>
          </div>
          <button
            className={`my-5 h-10 bg-[var(--custom-bg-color)] text-white px-4 py-2 rounded-3xl text-sm font-semibold ${
              file ? 'cursor-pointer' : 'cursor-not-allowed opacity-70'
            }`}
            onClick={handleSubmit}
            disabled={!file}
          >
            Upload
          </button>
        </div>

        {/* Display error message */}
        {error && <p className='w-full text-red-500 my-2'>Error: {error}</p>}

        <div className='flex flex-col mt-8 space-y-2 w-full md:w-[600px]'>
          <p>Files in your account:</p>
          {/* table */}
          <div className='overflow-y-auto max-h-72 w-full'>
            <table className='min-w-full border-collapse shadow-md table-fixed'>
              <thead className='bg-[#863e39] text-white sticky top-0'>
                <tr>
                  <th className='px-4 py-2 border w-1/2'>Filename</th>
                  <th className='px-4 py-2 border w-1/4'>Size</th>
                  <th className='px-4 py-2 border w-1/4'>Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {userFiles.length === 0 ? (
                  <tr>
                    <td
                      colSpan='3'
                      className='text-center py-2'
                    >
                      No files found.
                    </td>
                  </tr>
                ) : (
                  userFiles.map((d) => (
                    <tr
                      key={d.id}
                      className='hover:bg-[#f1c4c2] even:bg-gray-100'
                    >
                      <td className='px-4 py-2 border truncate'>
                        {getFormattedFilename(d.file)}
                      </td>
                      <td className='px-4 py-2 border'>
                        {getFormatFileSize(d.file_size)}
                      </td>
                      <td className='px-4 py-2 border'>
                        {getFormattedDate(d.uploaded_at)}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
