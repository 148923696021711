import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    getValues,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    trigger();
  }, [trigger]);

  // Function to handle registration
  const mutation = useMutation({
    mutationFn: async (data) => {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseUrl}/api/auth/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }

      return response.json();
    },
    // Function on successful registration
    onSuccess: (data) => {
      toast.success(data.message);
      navigate('/');
    },
    // Function to handle error while registration
    onError: (error) => {
      console.error('Registration error:', error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  return (
    <div className='w-screen h-[calc(100vh-var(--custom-header-height))] px-3'>
      {/* Main content container */}
      <div className='flex items-center justify-center px-3 w-full h-full py-3'>
        {/* Form container */}
        <form
          className='flex flex-col items-center justify-center w-[500px] px-2 py-3'
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className='font-normal my-5 text-lg'>
            Please register an account
          </h3>

          {/* Username field */}
          <div className='flex flex-col md:flex-row items-start mx-auto my-2 w-full md:gap-5'>
            <label
              htmlFor='username'
              className='font-normal text-sm w-44 md:whitespace-nowra'
            >
              Username
            </label>
            <div className='flex flex-col space-y-1 w-full'>
              <input
                type='text'
                name='username'
                placeholder='like john_09'
                className='focus:outline-none bg-transparent border border-[#D9D9D9] p-2 h-9 rounded-sm w-full'
                {...register('username', {
                  required: 'Username is a required field.',
                  minLength: {
                    value: 3,
                    message: 'Username must be at least 3 characters long.',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Username cannot exceed 15 characters.',
                  },
                  pattern: {
                    value:
                      /^(?=[a-zA-Z0-9])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
                    message:
                      'Username can only contain letters, numbers, underscores, and periods.',
                  },
                })}
              />
              {errors.username && (
                <p className='text-sm text-[var(--custom-bg-color)] italic'>
                  {errors.username.message}
                </p>
              )}
            </div>
          </div>

          {/* Email field */}
          <div className='flex flex-col md:flex-row items-start mx-auto my-2 w-full md:gap-5'>
            <label
              htmlFor='email'
              className='font-normal text-sm w-44 md:whitespace-nowrap'
            >
              Email
            </label>
            <div className='flex flex-col space-y-1 w-full'>
              <input
                type='email'
                placeholder='like john@gmail.com'
                className='focus:outline-none bg-transparent border border-[#D9D9D9] p-2 h-9 rounded-sm w-full'
                {...register('email', {
                  required: 'Email is a required field.',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Please enter a valid email address',
                  },
                })}
              />
              {errors.email && (
                <p className='text-sm text-[var(--custom-bg-color)] italic'>
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>

          {/* Password field */}
          <div className='flex flex-col md:flex-row items-start mx-auto my-2 w-full md:gap-5'>
            <label
              htmlFor='password'
              className='font-normal text-sm w-44 md:whitespace-nowrap'
            >
              Password
            </label>
            <div className='flex flex-col space-y-1 w-full'>
              <input
                type='password'
                placeholder='type password'
                className='focus:outline-none bg-transparent border border-[#D9D9D9] p-2 h-9 rounded-sm w-full'
                {...register('password', {
                  required: 'Password is a required field.',
                })}
              />
              {errors.password && (
                <p className='text-sm text-[var(--custom-bg-color)] italic'>
                  {errors.password.message}
                </p>
              )}
            </div>
          </div>

          {/* Confirm Password field */}
          <div className='flex flex-col md:flex-row items-start mx-auto my-2 w-full md:gap-5'>
            <label
              htmlFor='confirm_password'
              className='font-normal text-sm w-44 md:whitespace-nowrap'
            >
              Confirm Password
            </label>
            <div className='flex flex-col space-y-1 w-full'>
              <input
                type='password'
                placeholder='type password again'
                className='focus:outline-none bg-transparent border border-[#D9D9D9] p-2 h-9 rounded-sm w-full'
                {...register('confirm_password', {
                  required: 'It is a required field.',
                  validate: (value) =>
                    value === getValues('password') ||
                    'Passwords do not match.',
                })}
              />
              {errors.confirm_password && (
                <p className='text-sm text-[var(--custom-bg-color)] italic'>
                  {errors.confirm_password.message}
                </p>
              )}
            </div>
          </div>

          {/* Access Code field */}
          <div className='flex flex-col md:flex-row items-start mx-auto my-2 w-full md:gap-5'>
            <label
              htmlFor='access_code'
              className='font-normal text-sm w-44 md:whitespace-nowrap'
            >
              Access Code
            </label>
            <div className='flex flex-col space-y-1 w-full'>
              <input
                type='text'
                placeholder='like ACB984'
                className='focus:outline-none bg-transparent border border-[#D9D9D9] p-2 h-9 rounded-sm w-full'
                {...register('access_code', {
                  required: 'It is a required field.',
                })}
              />
              {errors.access_code && (
                <p className='text-sm text-[var(--custom-bg-color)] italic'>
                  {errors.access_code.message}
                </p>
              )}
            </div>
          </div>

          <button
            className={`font-normal my-6 bg-[var(--custom-bg-color)] text-white text-sm px-3 py-1 rounded-2xl w-44 ${
              isValid ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            type='submit'
            disabled={!isValid || mutation.isLoading}
          >
            {mutation.isLoading ? 'Registering...' : 'Register'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
