import React from 'react';
import { useUser } from '../context/UserContext';
import { useQuery } from '@tanstack/react-query';
import { fetchUserFiles } from './Upload';

const Account = () => {
  const { loggedInUser } = useUser();
  // const { data: userFiles = [] } = useQuery({
  //   queryKey: ['userFiles'],
  // });
  const { data: userFiles = [] } = useQuery({
    queryKey: ['userFiles'],
    queryFn: fetchUserFiles,
  });
  return (
    <div className='w-full h-screen px-16 py-8'>
      <p className='text-md my-3  flex items-center'>
        <span className='font-normal w-36'>Username:</span>
        <span className='ml-1'>{loggedInUser?.username}</span>
      </p>
      <p className='text-md my-3  flex items-center'>
        <span className='font-normal w-36'>Email:</span>
        <span className='ml-1'>{loggedInUser?.email}</span>
      </p>
      <p className='text-md my-3  flex items-center'>
        <span className='font-normal w-36'>Files Uploaded:</span>
        <span className='ml-1'>{userFiles?.length}</span>
      </p>
    </div>
  );
};

export default Account;
