// src/pages/router.js
import { createBrowserRouter } from 'react-router-dom';
import Layout from '../Layout'; // Adjust the import path as necessary
import Register from './Register';
import Login from './Login';
import Account from './Account';
import Upload from './Upload';
import Home from './Home';
import Chat from './Chat';

// importing protectedRoute
import ProtectedRoute from './protectedRoute';

const router = createBrowserRouter([
  {
    element: <Layout />, // Use the Layout component
    children: [
      {
        path: '/', // Nested routes will go here
        element: <Login />,
      },
      {
        path: '/home', // Nested routes will go here
        element: (
          <ProtectedRoute>
            <Home />,
          </ProtectedRoute>
        ),
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/account',
        element: (
          <ProtectedRoute>
            <Account />,
          </ProtectedRoute>
        ),
      },
      {
        path: '/upload',
        element: (
          <ProtectedRoute>
            <Upload />,
          </ProtectedRoute>
        ),
      },
      {
        path: '/chat',
        element: (
          <ProtectedRoute>
            <Chat />,
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;
