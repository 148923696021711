import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

// user context
import { useUser } from '../context/UserContext';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const { setUser } = useUser();
  const { isAuthenticated, setLogin } = useAuth();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const navigate = useNavigate();

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  const mutation = useMutation({
    mutationFn: async (data) => {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseUrl}/api/auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Login failed');
      }
      return response.json();
    },
    onSuccess: (data) => {
      toast.success(data.message);
      const username = data?.user?.username;
      const email = data?.user?.email;
      const csrf_token = data?.user?.csrf_token;
      const userData = {
        username: username,
        email: email,
        csrf_token: csrf_token,
      };
      setUser(userData);
      setLogin();
      navigate('/home');
    },
    onError: (error) => {
      // Display the error message from the backend
      const errorMessage =
        error.message || 'An error occurred. Please try again.';
      toast.error(errorMessage);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  return (
    <div className='w-screen h-[calc(100vh-var(--custom-header-height))] px-3'>
      <div className='flex items-center justify-center px-3 w-full h-full py-3'>
        <form
          className='flex flex-col items-center justify-center w-[500px] px-2 py-3'
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className='text-lg font-normal text-black mb-20'>
            Welcome to the DARE Platform. Please login to continue.
          </h3>

          <div className='w-72 flex flex-col items-start justify-center space-y-2 mx-auto my-1'>
            <label
              htmlFor='username'
              className='font-normal text-sm w-32'
            >
              Username
            </label>
            <div className='flex flex-col space-y-1'>
              <input
                type='text'
                placeholder='like john_09'
                className='w-72 focus:outline-none bg-transparent border border-[#D9D9D9] p-2 h-9 rounded-lg bg-white'
                {...register('username', {
                  required: 'Username is a required field.',
                })}
              />
              <p className='text-sm text-[var(--custom-bg-color)] italic w-72'>
                {errors?.username?.message}
              </p>
            </div>
          </div>

          <div className='w-72 flex flex-col items-start justify-center space-y-1 mx-auto my-2'>
            <label
              htmlFor='password'
              className='font-normal text-sm w-32'
            >
              Password
            </label>
            <div className='flex flex-col space-y-1'>
              <input
                type='password'
                placeholder='type password'
                className='w-72 focus:outline-none bg-transparent border border-[#D9D9D9] p-2 h-9 rounded-lg bg-white'
                {...register('password', {
                  required: 'Password is a required field.',
                })}
              />
              <p className='text-sm text-[var(--custom-bg-color)] italic w-72'>
                {errors?.password?.message}
              </p>
            </div>
          </div>

          <button
            className={`w-72 font-normal my-6 bg-[var(--custom-bg-color)] text-white px-3 py-1 rounded-md ${
              isValid && !mutation.isLoading
                ? 'cursor-pointer'
                : 'cursor-not-allowed'
            }`}
            type='submit'
            disabled={!isValid}
          >
            {mutation.isLoading ? 'Signing In...' : 'Sign In'}
          </button>
          <p className='text-sm underline w-72'>
            <Link>Forgot Password?</Link>
          </p>
          <p className='text-sm w-72 underline'>
            <Link to='/register'>Register</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
