const DummyData = [
  {
    id: 1,
    filename: 'filename1',
    size: '1KB',
    uploadedAt: new Date().toLocaleString().split(',')[0],
  },
  {
    id: 2,
    filename: 'filename2',
    size: '1KB',
    uploadedAt: new Date().toLocaleString().split(',')[0],
  },

  {
    id: 3,
    filename: 'filename3',
    size: '1KB',
    uploadedAt: new Date().toLocaleString().split(',')[0],
  },
  {
    id: 4,
    filename: 'filename4',
    size: '1KB',
    uploadedAt: new Date().toLocaleString().split(',')[0],
  },
];

export const textualData = [
  {
    id: 0,
    title: 'User interface',
    points: [
      'Simple text input field for user messages',
      'Area to display conversation history,Send button or enter key functionality',
      'Clear/reset conversation option',
    ],
  },

  {
    id: 1,
    title: 'Backend',
    points: [
      'Implement API calls to your chosen language model providers',
      'Handle authentication using API keys',
      'Manage conversation context/history',
    ],
  },

  {
    id: 2,
    title: 'Key features',
    points: [
      'Model selection dropdown (if supporting multiple models)',
      'Display user and AI messages distinctly',
      'Show loading indicator while waiting for AI response',
    ],
  },
  {
    id: 3,
    title: 'Technical considerations',
    points: [
      'Use websockets or long polling for real-time updates',
      'implement proper error handling for API failures',
      'Consider rate limiting to manage API usage',
    ],
  },
  {
    id: 4,
    title: 'User experience',
    points: [
      'Provide clear instructions on how to use the interface',
      'implement basic formatting for readability (e.g, markdown support)',
      'Consider rate limiting to manage API usage',
    ],
  },
];

export default DummyData;
